<template>
  <div>
		<div v-for="(item,i) of msgList" :key="i">
			<div class="zsjg_title">
				<img style="width:23px;" src="@/assets/icon/msg.png">
				<span class="title">{{typeName(item)}}</span>
			</div>
			<div class="zsjgsx">
				<!-- 加工通知 -->
				<div class="zsjgsx_text text_msg" v-if="item.messageType==1">
					<span>你{{item.userType==1?'发布':item.userType==2?'负责':'领取'}}的{{item.taskFatherId!=item.taskId?'子':''}}任务</span>
					<span class="text_bold">{{item.taskName}}</span>
					<span class="text_bold text_red">{{tsakStatus(item.taskType,item.taskFatherId==item.taskId)}}</span>
					<span>可以前往</span>
					<span class="text_bold">{{userTypeShow(item.userType)}}</span>
					<span class="text_blue" @click="tapCheckCenter(item)">查看</span>
				</div>

				<!-- 任务划分通知 -->
				<div class="zsjgsx_text text_msg" v-if="item.messageType==2">
					<span class="text_bold">{{item.fzrName}}</span>
					<span>参加</span>
					<span class="text_bold">{{item.taskName}}</span>
					<span>负责人招募</span>
					<span>请前往查看</span>
					<span class="text_blue" v-if="!item.jgFzrAndTaskId" @click="tapCheckTask(item)">查看</span> 
					<span class="text_blue" v-else>已确定{{item.userName}}为负责人</span> 
				</div>

				<!-- 成果通知 -->
				<div class="zsjgsx_text text_msg" v-if="item.messageType==3">
					<span class="text_bold">{{item.fzrName}}</span>
					<span>(负责人)</span>
					<span>已提交</span>
					<span class="text_bold">{{item.taskName}}</span>
					<span>知识成果</span>
					<span class="text_blue" @click="tapCgSearch(item)">前往查看</span>
				</div>

				<!-- 投诉通知 -->
				<div class="zsjgsx_text text_msg" v-if="item.messageType==4">
					<span>你{{item.userType==2?'负责':'加工'}}的项目</span>
					<span class="text_bold">{{item.taskName}}</span>
					<span>的发布人投诉了你</span>
					<span class="text_blue" @click="tapSearchYy(item)">查看投诉原因</span>
				</div>

				<!-- 加工酬劳通知 -->
				<div class="zsjgsx_text text_msg" v-if="item.messageType==5">
					<span>您负责的</span>
					<span class="text_bold">{{item.taskName}}</span>
					<span>已完成</span>
					<span>并获得了</span>
					<span class="text_bold text_red">{{item.money}}知识币</span>
				</div>


				<!-- 反馈通知 -->
				<div class="zsjgsx_text text_msg" v-if="item.messageType==6">
					<span>{{item.fzrName}}</span>
					<span>在项目</span>
					<span class="text_bold">{{item.taskName}}</span>
					<span>提出反馈</span>
					<span class="text_blue" @click="tapSearchBackInfo(item)">查看反馈内容</span>
				</div>


				<!-- 违约通知 -->
				<div class="zsjgsx_text text_msg" v-if="item.messageType==7">
					<span>{{item.fzrName}}</span>
					<span>在项目</span>
					<span class="text_bold">{{item.taskName}}</span>
					<span>提出反馈</span>
					<span class="text_blue">查看反馈内容</span>
				</div>

				<!-- 招募通知 -->
				<div class="zsjgsx_text text_msg" v-if="item.messageType==8">
					<span>您参加项目</span>
					<span class="text_bold">{{item.taskName}}</span>
					<span>的招募</span>
					<span class="text_bold" :class="{text_red:item.complaintStatus!=1}">{{item.complaintStatus==1?'已通过':'未通过'}}</span>
					<template v-if="item.complaintStatus==1">
						<span>请前往</span>
						<span class="text_blue" @click="tapCheckCenter(item)">查看</span>
					</template>
				</div>

				<div class="zsjgsx_text text_time">
					{{dateType(item.taskTime,true)}}   <span @click="tapDelete(item,i)">删除</span>
				</div>
			</div>
		</div>
		<div v-if="msgList.length<=0" style="text-align:center">
			暂无知识加工消息数据
		</div>
		<div class="pagination" v-if="msgList.length>0">
			<el-pagination
				background
				layout="prev, pager, next"
				:page-size="limit"
				@current-change="getPage"
				:total="allPage">
			</el-pagination>
		</div>
		
		<div class="keepoOut" v-if="isShowKeep" @click.stop="isShowKeep=false">
      <div class="keepDiv" @click.stop>
        <p class="keepTitle">原因</p>
        <p class="keepTitle1" style="margin-top:30px">
          {{tsContent}} 
        </p>
        <el-button type="primary" class="keepBtn" @click.stop="isShowKeep=false">确定</el-button>
      </div>
    </div>


		<div class="keepoOut" v-if="isShowBackInfoTc" @click.stop="isShowBackInfoTc=false">
      <div class="keepDiv" @click.stop>
        <p class="keepTitle">反馈内容</p>
        <p class="keepTitle1" style="margin-top:30px">
          {{backInfoContent}} 
        </p>
				<img class="keepImg" :src="api.LoginURL.concat('/resources/trading/upload'+backInfoImg)">
        <el-button type="primary" class="keepBtn" @click.stop="isShowBackInfoTc=false">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { QUERYED,UPDATES,INSERT,DELETE } from "@/services/dao.js"; 
export default {
	data(){
		return{
			userid:'',
			msgList:[],
			offset:0,  //当前页
			limit:10,  //每页条数
			allPage:1000,  //页数

			//投诉弹窗
			isShowKeep:false,
			tsContent:'',

			//反馈弹窗
			isShowBackInfoTc:false,
			backInfoContent:'',
			backInfoImg:'',
		}
	},
	created(){
		let userid = this.$store.state.userinfo.id;   
    if(!userid){
      this.$alert('请登录!', '提示', {
        confirmButtonText: '确定',
        callback: () => {
          this.$router.push({path:'/login'})
        }
      });
      return
    }
    this.userid = userid
    this.getMsgInfo()
	},
	methods:{
		//获取消息通知
		async getMsgInfo(){
			let res = await QUERYED(
        "post","",
        "jgMessage(where: {userId: {_eq:"+ this.userid +"}},order_by: {taskTime: desc},offset:"+this.offset+",limit:"+this.limit+") "
				+"{ id messageType taskName taskType taskId complaintStatus backInformId money userType userId"
				+" reason taskTime userType fzrId fzrName taskFatherId taskTypeTow beuserid bename}"
				+"jgMessage_aggregate(where: {userId: {_eq:"+ this.userid +"}}) {aggregate {count}}"
			);
      if(res.success){
				this.msgList = res.data.jgMessage
				this.allPage= res.data.jgMessage_aggregate.aggregate.count
				this.searchIsFzr(this.msgList,0)
      }
		},

		async searchIsFzr(list,num){
			// 28
			if(list.length<=num+1)return
			let {messageType,taskId,taskTypeTow } = list[num]
			if(messageType==2){
				let tableStr = ""
				//纸质化加工表
				if(taskTypeTow==0){
					tableStr ="jgPeper(where:{id:{_eq:"+taskId+"}}) {jgFzrAndTaskId }"
				}else if(taskTypeTow == 1){
					//数字产品发布表
					tableStr ="jgDigitalPro(where:{id:{_eq:"+taskId+"}}) {jgFzrAndTaskId}"
				}else if(taskTypeTow==2){
					//词条发布表
					tableStr ="jgEntryPro(where:{id:{_eq:"+taskId+"}}) {jgFzrAndTaskId}"
				}
				let res = await QUERYED("post","",tableStr);
				if(res.success){
					let jgFzrAndTaskId = ''
					if(taskTypeTow==0){
						jgFzrAndTaskId =  res.data.jgPeper[0].jgFzrAndTaskId
					}else if(taskTypeTow==1){
						jgFzrAndTaskId =  res.data.jgDigitalPro[0].jgFzrAndTaskId
					}else if(taskTypeTow==2){
						jgFzrAndTaskId =  res.data.jgEntryPro[0].jgFzrAndTaskId
					}
					this.$set(list[num],'jgFzrAndTaskId',jgFzrAndTaskId)
					if(jgFzrAndTaskId){
						let user = await QUERYED("post","","User(where:{id:{_eq:"+jgFzrAndTaskId+"}}) {name}");
						if(user.success){
							this.$set(list[num],'userName',user.data.User[0].name)
						}
					}
				}
			}
			num++
			this.searchIsFzr(list,num)
		},

		//获取分页
		getPage(e){
			this.offset = (e-1)*10
			this.getMsgInfo()
		},

		//分类
		typeName(item){
			// 1：加工通知 2，任务划分通知 3:成果通知 4：投诉通知 5：加工酬劳通知 6：反馈通知
			switch (item.messageType) {
				case 1:
					return '加工通知'
				case 2:
					return '任务划分通知'
				case 3:
					return '成果通知'
				case 4:
					return '投诉'
				case 5:
					return '加工酬劳通知'
				case 6:
					return '反馈通知'
				case 7:
					return '违约通知'
				case 8:
					return '招募通知'
			}
		},

		//警告弹窗
    msgWarn(message,type){
      this.$message({
        message,type: type||'warning'
      });
    },


		//投诉状态显示
		// clComplaintStatus(type){
		// 	switch (type) {
		// 		case 1:
					
		// 			break;
		// 		case 1:
					
		// 			break;
		// 		case 1:
					
		// 			break;
		// 	}
		// },

		//查看反馈内容
		async tapSearchBackInfo(item){
			let res = await QUERYED(
        "post","",
        "jgBackInform(where: {id: {_eq:"+ item.backInformId +"}}) "
				+"{id imgTab imgProblem taskId taskName}"
			);
			if(res.success){
				this.isShowBackInfoTc = true
				this.backInfoContent = res.data.jgBackInform[0].imgProblem
				this.backInfoImg = res.data.jgBackInform[0].imgTab
			}else{
				this.msgWarn('获取数据失败,请重试')
			}
		},

		//查看发布内容
		tapFbBackInfo(item){

			let {taskId,taskTypeTow} = item
			this.$router.push({path:'/taskDetail',query:{id:taskId,type:taskTypeTow}})
			// console.log();
			// /taskDetail?id=624059826&type=2
		},

		//查看投诉原因
		tapSearchYy(item){
			this.isShowKeep = true
			this.tsContent = item.reason||'未写投诉原因'
		},

		//时间格式转化
		dateType(value,boo){ 
			let date = new Date();
			if(value)date = new Date(value)
			let y = date.getFullYear();
			let MM = date.getMonth() + 1;
			MM = MM < 10 ? "0" + MM : MM;
			let d = date.getDate();
			d = d < 10 ? "0" + d : d;
			if(boo){
				let h = date.getHours();
				h = h < 10 ? "0" + h : h;
				let m = date.getMinutes();
				m = m < 10 ? "0" + m : m;
				let s = date.getSeconds();
				s = s < 10 ? "0" + s : s;
				return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
			}
			return y + "-" + MM + "-" + d
		},

		//任务状态
		tsakStatus(type,boo){
			// 主任务状态：0未启动，1已逾期，2加工中，3已完成，4已取消
			// 子任务状态：0未领取，1已领取未开始加工，2加工中，3加工完成，4校对完成，5审核完成，6已逾期，7已违约
			if(boo){
				switch (type) {
					case 1:
						return '已逾期'
					case 2:
						return '已启动'
					case 3:
						return '已完成'
					case 4:
						return '已取消'
					default : '任务处于其他状态'
				}
			}else{
				switch (type) {
					case 2:
						return '已启动'
					case 4:
						return '已提交'
					case 5:
						return '审核完成'
					case 6:
						return '已逾期'
					case 7:
						return '已违约'
					default : '任务处于其他状态'
				}
			}
		},

		//用户身份
		userTypeShow(type){
			switch (type) {
					case 1:
						return '我的发布'
					case 2:
						return '我负责的'
					case 3:
						return '我加工的'
				}
		},

		//任务划分通知查看
		async tapCheckTask(item){
			let {taskId,taskTypeTow,fzrId,fzrName} = item
			let sql = "jgPeper(where: {id: {_eq:"+ taskId+"}}){jgFzrAndTaskId}"
			if(taskTypeTow==1){
				sql = "jgDigitalPro(where: {id: {_eq:"+ taskId+"}}){jgFzrAndTaskId}"
			}
			if(taskTypeTow==2){
				sql = "jgEntryPro(where: {id: {_eq:"+ taskId+"}}){jgFzrAndTaskId}"
			}
			let res = await QUERYED(
        "post","",sql
			);
			if(!res.success)this.msgWarn('获取数据失败,请重试')
			let jgFzrAndTaskId = ''
			if(taskTypeTow==0)jgFzrAndTaskId = res.data.jgPeper[0].jgFzrAndTaskId
			else if(taskTypeTow==1)jgFzrAndTaskId = res.data.jgDigitalPro[0].jgFzrAndTaskId
			else jgFzrAndTaskId = res.data.jgEntryPro[0].jgFzrAndTaskId
			if(jgFzrAndTaskId){
				this.msgWarn('该项目已确定负责人')
				return
			}
			this.$router.push({path:'/taskDivideAffirm',query:{id:taskId,fzrId,fzrName,taskTypeTow}})
		},

		//加工通知查看
		tapCheckCenter(item){
			let path = ''
			switch (item.userType ) {
				case 1:path = '/posted'
					break;
				case 2:path = '/charge'
					break;
				case 3:path = '/made'
					break;
			}
			this.$router.push({path})
		},

		//点击查看时
		tapCheck(item){
			// /posted  发布
			// /charge 负责
			// /made  加工
		},

		//点击删除
		async tapDelete(item,i){
			let del = await DELETE(
				"post","",
				'delete_jgMessage(where: {id:{_eq:'+item.id+'}}) {affected_rows}'
			)
			if(del.success){
				this.msgWarn('删除成功','success')
				this.getMsgInfo()
			}else{
				this.msgWarn('删除失败,请重试')
			}
		},

		//成果通知
		async tapCgSearch(item){
			let {taskId,taskTypeTow} = item
			console.log(taskTypeTow,123456);
			let name = 'jgPeper'
			if(taskTypeTow==1)name = 'jgDigitalPro'
			else if(taskTypeTow==2)name = 'jgEntryPro'
			let res = await QUERYED(
        "post","",
        name+"(where: {id: {_eq:"+ taskId +"}}) {jgFzrAndTaskId}"
			);
      if(!res.success){
				this.msgWarn('获取数据失败')
				return
      }
			console.log(res.data);
			this.$router.push({path:'/Achievement',query:{id:taskId,type:taskTypeTow,fzrid:res.data[name][0].jgFzrAndTaskId,state:0}})
		}
	}
}
</script>

<style scoped>
.zsjg_title{
	display: flex;
	align-items: center;
}
.title{
	margin-left: 20px;
	font-size: 16px;
	font-weight: bold;
	color: #000000;
}
.zsjgsx{
	margin-left: 7px;
	padding: 15px 30px;
	border-left: 2px #0084FF dotted;
}
.zsjgsx_text{
	display: flex;
	align-items: center;
}
.text_msg{
	color: #000000;
	font-size: 14px;
}
.text_msg>span+span{
	margin-left: 5px;
}
.text_bold{
	font-weight: bold;
}
.text_red{
	color: #E5000A;
}
.text_blue{
	color: #0084FF;
	cursor: pointer;
	border-bottom: 1px solid #0084FF;
}
.text_time{
	margin-top: 15px;
	color: #0084FF;
	font-size: 14px;
}
.text_time>span{
	margin-left: 150px;
	cursor: pointer;
}
.pagination{
	padding-top: 30px;
	display: flex;
	justify-content: center;
}

/* 弹窗 */
.keepoOut{
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0,0,0,0.2);
	left: 0;
  top:0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.keepDiv{
  width: 454px;
  padding:20px 40px;
  background-color: #FFFFFF;
  box-sizing: border-box;
  position: relative;
}
.keepTitle{
  font-size: 24px;
  color: #000000;
  text-align: center;
}
.keepTitle1{
  font-size: 14px;
  text-align: center;
}
.keepImg{
	max-width: 100%;
}
.keepBtn{
  margin: 0 auto;
  margin-top: 30px;
  display: block;
}
.keepBack{
  width: 21px;
  height: 21px;
  border:2px solid #999;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right:10px;
  cursor: pointer;
}
.keepBack:hover{
  opacity: 0.8;
}
.keepBack>i{
  font-size: 30px;
  color:#999;
}
</style>